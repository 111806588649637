import React from 'react';
import { Link } from "gatsby";
import * as Icon from 'react-feather';

import besik from "../../assets/images/shop-image/meltem-smart-kral-besik.png";
import bisiklet from "../../assets/images/shop-image/elektrikli-bisiklet.png";
import yagdanlik from "../../assets/images/shop-image/tupperware-yagdanlik.png";
import bosch from "../../assets/images/shop-image/bosh-akulu-supurge.png";


import koltuk from "../../assets/images/shop-image/doner-koltuk-ikinci-el.png";
import tencere from "../../assets/images/shop-image/karaca-tencere-seti.png";
import airfrey from "../../assets/images/shop-image/philips-airfrey.png";
import caydanlik from "../../assets/images/shop-image/akdeniz-tombik-caydanlik.png";

const ProductCard = () => {
    return (
        <div className="shop-area ptb-80">
            <div className="container">


                <div className="row justify-content-md-center">

                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-products">
                            <Link to="/bebek-besigi-meltem-smart">
                                <div className="products-image">
                                    <img src={besik} alt="image" />
                                </div>
                            </Link>
                            <div className="products-content">
                                <h3>
                                    <Link to="/bebek-besigi-meltem-smart">
                                        Meltem Smart Bebek Beşiği
                                    </Link>
                                </h3>
                                <p>
                                    Az Kullanılmış
                                </p>

                                <Link to="https://wa.me/903326062054?text=meltem%20smart%20besik%20icin%20yaz%C4%B1yorum" className="btn btn-primary">
                                    Whatsapptan Teklif Ver
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-products">
                            <Link to="/akdeniz-tombik-caydanlik">
                                <div className="products-image">
                                    <img src={caydanlik} alt="image" />
                                </div>
                            </Link>
                            <div className="products-content">
                                <h3>
                                    <Link to="/akdeniz-tombik-caydanlik">
                                        Akdeniz Tombik Çaydanlık
                                    </Link>
                                </h3>
                                <p>
                                    Sıfır Kutulu
                                </p>
                                <Link to="https://wa.me/903326062054?text=Akdeniz%20çaydanlık%20%20icin%20yaz%C4%B1yorum" className="btn btn-primary">
                                    Whatsapptan Teklif Ver
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-products">
                            <Link to="/alba-fold-elektrikli">
                                <div className="products-image">
                                    <img src={bisiklet} alt="image" />

                                </div>
                            </Link>
                            <div className="products-content">
                                <h3>
                                    <Link to="/alba-fold-elektrikli">
                                        Alba Fold Elektrikli Bisiklet
                                    </Link>
                                </h3>
                                <p>
                                    Az Kullanılmış
                                </p>


                                <Link to="#" className="btn btn-light">
                                    TAKAS TAMAMLANDI
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-products">
                            <Link to="/tupperware-yagdanlik">
                                <div className="products-image">
                                    <img src={yagdanlik} alt="image" />
                                </div>
                            </Link>
                            <div className="products-content">
                                <h3>
                                    <Link to="/tupperware-yagdanlik">
                                        Tupperware Yağdanlık
                                    </Link>
                                </h3>
                                <p>
                                    Sıfır Kutulu

                                </p>

                                <Link to="#" className="btn btn-light">
                                    STOK TÜKENDİ
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-products">
                            <Link to="/akulu-supurge">

                                <div className="products-image">
                                    <img src={bosch} alt="image" />


                                </div>
                            </Link>

                            <div className="products-content">
                                <h3>
                                    <Link to="/akulu-supurge">
                                        Bosch Akülü Süpürge
                                    </Link>
                                </h3>
                                <p>
                                    Sıfır Kutulu Garantili
                                </p>
                                <Link to="#" className="btn btn-light">
                                    STOK TÜKENDİ
                                </Link>
                            </div>
                        </div>
                    </div>



                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-products">
                            <Link to="#">
                                <div className="products-image">
                                    <img src={koltuk} alt="image" />
                                </div>
                            </Link>
                            <div className="products-content">
                                <h3>
                                    <Link to="#">
                                        Ayarlanabilir Döner Koltuk
                                    </Link>
                                </h3>
                                <p>
                                    İkinci El
                                </p>
                                <Link to="#" className="btn btn-light">
                                    TAKAS TAMAMLANDI
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-products">
                            <Link to="#">

                                <div className="products-image">
                                    <img src={tencere} alt="image" />

                                </div>
                            </Link>

                            <div className="products-content">
                                <h3>
                                    <Link to="#">
                                        Karaca Tencere Seti
                                    </Link>
                                </h3>
                                <p>
                                    Sıfır Kutulu İhtiyaç Fazlası
                                </p>


                                <Link to="#" className="btn btn-light">
                                    TAKAS TAMAMLANDI
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-products">
                            <Link to="#">
                                <div className="products-image">
                                    <img src={airfrey} alt="image" />

                                </div>
                            </Link>
                            <div className="products-content">
                                <h3>
                                    <Link to="#">
                                        Philips Airfryer HD9252/90
                                    </Link>
                                </h3>
                                <p>
                                    Sıfır Kutulu Garantili
                                </p>

                                <Link to="#" className="btn btn-light">
                                    STOK TÜKENDİ
                                </Link>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default ProductCard;  