import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import ProductCard from '../components/Shop/ProductCard';
import { Divide, DivideCircle } from "react-feather";

const Shop = () => (
    <Layout>
        <SEO title="TAKAS ÜRÜNLERİ" />
        <Navbar />
        <div className="page-title-area">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">


                        <h2 style={{margin:'30px'}} >ÜRÜNLER</h2>
              

                        <h5>Ürününü Seç </h5> 
                        <h5>Whatsapp Üzerinden Teklifini Ver</h5> 
                        <h5>Teklifiniz Onaylanınca </h5> 
                        <h5>Konya İçi Adresinize Gelsin</h5> 
                        <h5>Takasınız Yerinde Olsun</h5> 
                        <h5>İhtiyacınıza 0.00 ₺ ye Sahip Olun </h5> 

                        <ProductCard />

                        <h5>İlginiz için teşekkür ederiz</h5> 
                        <h5>Elimizde fotoğraflaması ve kontrolleri devam eden ürünleri buradan takip edebilirsiniz</h5> 
                        <h5>Lütfen ürün detaylarını dikkatle inceleyiniz </h5> 
                      

                    </div>
                </div>
            </div>
        </div>

<br></br>
<br></br>

<br></br>

<br></br>

        <Footer />
    </Layout>
)

export default Shop;